import request from '@/utils/request';
import config from '@/config';

// 获取账户列表
export const getAccounts = params =>
  request.get('/exchange-api/withdraw/listAccounts', { params });
// 提现
export const getWithdraw = params =>
  request.post(`/exchange-api/withdraw/do`, params);
// 上传
export const uploadFileUrl = () =>
  `${config.baseApi}/file-api/file-server/saas/add`;
// 新增账户
export const getAddAccount = params =>
  request.post('/exchange-api/withdraw/addAccount', params);
// 查询用户提现情况
export const getWithdrawRecordList = params =>
  request.get(`/exchange-api/withdraw/myWithdraws`, { params });

/* 查询钱包数据 */
export const getAllAcc = params =>
  request.get(`/exchange-api/wallet/get`, { params });

// 手续费
export const getWithdrawFee = params =>
  request.get(`/exchange-api/fee/withdrawFee`, { params });

// 提现-绑定账户-删除
export const getDeleteAcc = params =>
  request.post(`/exchange-api/withdraw/delAccount`, params);

// 获取所有国家和地区
export const getAllCountries = () =>
  request.get('/setting-api/area/getAllCountry');

// 获取收款账户列表
export const getWithdrawAccList = params =>
  request.get(`/exchange-api/withdraw/myAccounts`, { params });

// 提现记录回单下载
export const previewPdf = params =>
  request.get(`exchange-api/withdraw/previewPdf`, {
    params,
    responseType: 'blob',
  });
export const queryFundExitList = params =>
  request.post('/exchange-api/withdraw/queryFundExitList', params);
export const checkFile = params =>
  request.post('/exchange-api/withdraw/checkFile', params, {
    responseType: 'blob',
  });
export const addFundExit = params =>
  request.post('/exchange-api/withdraw/addFundExit', params);
export const querySettlementList = params =>
  request.post('/exchange-api/withdraw/querySettlementList', params);

export const recharge = params =>
  request.post('/exchange-api/withdraw/recharge', params);

export const getProduct = params =>
  request.post('/exchange-api/limit/queryProduct', params);

export const getFeeList = params =>
  request.post(`/exchange-api/limit/queryFeeList`, params);
